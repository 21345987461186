
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: normal;
  font-family: monospace;
  font-size: 22px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Loading..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}